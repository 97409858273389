import ChipHowToPlay from './module/ChipHowToPlayI18n';
import GainEntryExplain from './module/GainEntryExplainI18n';
import GuideRole from './module/GuideRoleI18n';
import YuliAttributes from './module/YuliAttributesI18n';
import YuliHowToPlay from './module/YuliHowToPlayI18n';

/**当前主文件 - 版本号 */
export const version = 10;

/**芯片玩法说明 - 语言环境 */
export const ChipHowToPlayI18n = ChipHowToPlay;

/**yuli属性说明 - 语言环境 */
export const GainEntryExplainI18n = GainEntryExplain;

/**角色技能说明 - 语言环境 */
export const GuideRoleI18n = GuideRole;

/**芯片词条属性说明 - 语言环境 */
export const YuliAttributesI18n = YuliAttributes;

/**净化地脉玩法说明 - 语言环境 */
export const YuliHowToPlayI18n = YuliHowToPlay;