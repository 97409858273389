<template>
    <div
        class="cp-backIcon"
        @click="clickHandle"
    >
        <img
            :src="require('@/assets/images/YuliApp/backUI/button_return.png')"
        />
        <span class="cp-backIcon-text">{{ innerText }}</span>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, ref, computed, nextTick } from "vue";
import _ from "lodash";

export default {
props: {
    clickHandle: {
        type: Function,
        default: () => {},
    },
    innerText: {
        type: String,
        default: '',
    }
},
setup(props, { emit }) {
    
    return {
    
    };
},
};
</script>
  
<style lang="less" scoped>
    .cp-backIcon{
        position: absolute;
        width: 79px;
        height: 26px;
        bottom: 40%;
        left: 0;
        transform: translate(0, 50%);
        img{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        &-text{
            position: absolute;
            width: 38px;
            top: 50%;
            right: 2px;
            transform: translate(0, -50%);
            color: #ffffff;
            font-size: 10px;
            font-weight: 500;
            font-family: "PingFang SC";
            text-align: left;
            line-height: 12px;
        }
    }
</style>
  