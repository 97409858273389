export const tippotH5 = {
  // 消息列表
  messages: "/api/msg/v1/messages",
};

export const API_COMM = {
  //获取签名
  user_sign: "/api/core/v1/upload_sign?platform=web&mime_type=image",
  //获取nonce
  user_nonce: "/api/auth/v1/nonce",
  //发送验证码
  user_sms: "/api/auth/v1/sms",
  //登录
  user_login: "/api/auth/v1/login",
  //登出
  user_logout: "/api/auth/v1/logout",
  // 埋点
  empty: "/api/stat/v1/empty",
  //事件埋点上报
  event_tracks: "/api/stat/v1/event_tracks",
  //个人中心详情
  user_v1: "api/auth/v1/users/{path_user_id}",
  // user_v1: (path_user_id) => `/api/auth/v1/users/${path_user_id}`,
};

/**语言包 */
export const LANGUAGE_PACK = {
  get_all_language: process.env.NODE_ENV !== "production" ? 
  'https://yuliverse-beta.s3.amazonaws.com/cfg/app_language_version_test.json' :
  'https://www.yuliverse.com/cfg/app_language_version.json'
}
