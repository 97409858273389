import Cookies from "js-cookie";

const TokenKey = "sheshu-user-token";
const UserID = "sheshu-userid";
const UserInfo = "sheshu-userinfo"

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 7 });
}

export function setUserID(id) {
  return Cookies.set(UserID, id);
}

export function getUserID() {
  return Cookies.get(UserID);
}

export function removeToken() {
  Cookies.remove(UserID);
  Cookies.remove(UserInfo);
  return Cookies.remove(TokenKey);
}

export function setUserInfo(userInfo) {
  return Cookies.set(UserInfo, userInfo);
}

export function getUserInfo() {
  return Cookies.get(UserInfo);
}
