export default {
  "chiprule_bty": {
    "en": "Bty.",
    "ja": "Bty.",
    "zh": "Bty.",
    "zh_cn": "Bty."
  },
  "chiprule_bty_attr": {
    "en": "Perception Factor",
    "ja": "報奨金要素",
    "zh": "增益因子",
    "zh_cn": "增益因子"
  },
  "chiprule_bty_desc": {
    "en": "Extra Lost Pages gaining in Terra purifying",
    "ja": "祈りのバフ効果を増強します",
    "zh": "增加祈福增益效果",
    "zh_cn": "增加祈福增益效果"
  },
  "chiprule_cbt": {
    "en": "Cbt.",
    "ja": "Cbt.",
    "zh": "Cbt.",
    "zh_cn": "Cbt."
  },
  "chiprule_cbt_attr": {
    "en": "Co-bounty Factor",
    "ja": "共同報奨金要素",
    "zh": "共同增益因子",
    "zh_cn": "共同增益因子"
  },
  "chiprule_cbt_desc": {
    "en": "The chance allow Yulis whose element differ from bounty of the day still can get the bounty buff",
    "ja": "その日のバフ属性と異なるユリもバフを獲得できる可能性を得る",
    "zh": "一定幾率讓元素與當天祈福增益不同的Yuli仍然可以獲得祈福增益",
    "zh_cn": "一定几率让元素与当天祈福增益不同的Yuli仍然可以获得祈福增益"
  },
  "chiprule_chip_attr": {
    "en": "Enhancement Attributes",
    "ja": "強化される属性",
    "zh": "強化屬性",
    "zh_cn": "强化属性"
  },
  "chiprule_chip_desc": {
    "en": "Description",
    "ja": "説明",
    "zh": "描述",
    "zh_cn": "描述"
  },
  "chiprule_cst": {
    "en": "Cst.",
    "ja": "CST.",
    "zh": "CST.",
    "zh_cn": "CST."
  },
  "chiprule_cst_attr": {
    "en": "Crystallizing Factor",
    "ja": "結晶化要素",
    "zh": "結晶因子",
    "zh_cn": "结晶因子"
  },
  "chiprule_cst_desc": {
    "en": "Extra $ARG &$ART gaining in Terra purifying",
    "ja": "浄化中に追加の ARG と ART を獲得します",
    "zh": "凈化中獲得額外的 ARG & ART",
    "zh_cn": "净化中获得额外的 ARG & ART"
  },
  "chiprule_excst": {
    "en": "Excst.",
    "ja": "Excst.",
    "zh": "Excst.",
    "zh_cn": "Excst."
  },
  "chiprule_excst_attr": {
    "en": "Ex-crystallizing Factor",
    "ja": "強化された結晶化要素",
    "zh": "額外結晶因子",
    "zh_cn": "额外结晶因子"
  },
  "chiprule_excst_desc": {
    "en": "Addition bonus rate, the basic extra bonus is 50%",
    "ja": "ボーナス加算率の上昇、基本追加ボーナスは50%です",
    "zh": "加成率，基礎加成50%",
    "zh_cn": "加成率，基础加成50%"
  },
  "chiprule_exprf": {
    "en": "Exprf.",
    "ja": "Exprf.",
    "zh": "Exprf.",
    "zh_cn": "Exprf."
  },
  "chiprule_exprf_attr": {
    "en": "Ex-purifying Factor",
    "ja": "強化された結晶化要素",
    "zh": "凈化因子",
    "zh_cn": "净化因子"
  },
  "chiprule_exprf_desc": {
    "en": "The chance to trigger extra bonus during Terra purifying",
    "ja": "浄化中に追加のボーナスを得るチャンスが発生します",
    "zh": "大地凈化過程中有幾率觸發額外加成",
    "zh_cn": "大地净化过程中有几率触发额外加成"
  },
  "chiprule_pcpt": {
    "en": "Pcpt.",
    "ja": "Pcpt.",
    "zh": "Pcpt.",
    "zh_cn": "Pcpt."
  },
  "chiprule_pcpt_attr": {
    "en": "Perception Factor",
    "ja": "知覚要素",
    "zh": "感知因子",
    "zh_cn": "感知因子"
  },
  "chiprule_pcpt_desc": {
    "en": "Extra Lost Pages gaining in Terra purifying",
    "ja": "浄化中に追加のロストページを獲得します",
    "zh": "地脈凈化中獲得的額外的「遺失殘頁」",
    "zh_cn": "地脉净化中获得的额外的“遗失残页”"
  },
  "chiprule_qlt": {
    "en": "Qlt.",
    "ja": "Qlt.",
    "zh": "Qlt.",
    "zh_cn": "Qlt."
  },
  "chiprule_qlt_attr": {
    "en": "Quality Factor",
    "ja": "品質要素",
    "zh": "質量因子",
    "zh_cn": "质量因子"
  },
  "chiprule_qlt_desc": {
    "en": "The chance to upgrade Purifying Result",
    "ja": "浄化結果のアップグレード確率上昇",
    "zh": "提升凈化效果的概率",
    "zh_cn": "提升净化效果的概率"
  },
  "h5_guide_gift": {
    "en": "Gift",
    "ja": "ギフト",
    "zh": "天賦",
    "zh_cn": "天赋"
  },
  "h5_guide_gift_content": {
    "en": "The mission of the Purifiers is to explore and purify Terra, and their talents will help them become great adventurers in the new world, explore unknown territories, and face challenges to maintain balance in the world.",
    "ja": "浄化者たちの使命は、Terraを探索し浄化することです。彼らの能力は、彼らが新しい世界で偉大な冒険家となり、未知の世界を探索し、世界のバランスを維持するための課題に立ち向かうために役立ちます。",
    "zh": "凈化者的使命是探索和凈化Terra，他們的天賦將幫助他們成為新世界的偉大冒險家，探索未知領土，並面臨維持平衡的挑戰在世界上。",
    "zh_cn": "净化者的使命是探索和净化Terra，他们的天赋将帮助他们成为新世界的伟大冒险家，探索未知领土，并面临维持平衡的挑战在世界上。"
  },
  "h5_guide_skill": {
    "en": "Skill",
    "ja": "スキル",
    "zh": "技能",
    "zh_cn": "技能"
  },
  "h5_guide_table_desc": {
    "en": "Overall, the higher the skill level, the better the purification result, and the higher the rewards obtained.",
    "ja": "全体として、スキルレベルが高いほど浄化の結果が良くなり、得られる報酬も高くなります。",
    "zh": "總體而言，技能等級越高，凈化效果越好，獲得的獎勵也越高。",
    "zh_cn": "总体而言，技能等级越高，净化效果越好，获得的奖励也越高。"
  },
  "h5_guide_tablehead_gv": {
    "en": "Guide Lv.",
    "ja": "Guide Lv.",
    "zh": "向導等級",
    "zh_cn": "向导等级"
  },
  "h5_guide_tablehead_sv": {
    "en": "Skill Lv.",
    "ja": "スキルLv.",
    "zh": "技能等級",
    "zh_cn": "技能等级"
  },
  "purify_content": {
    "en": "Now that you have found the Terra, you can start purifying. During the process of Terra purifying, Yuli will summon five purification glimmers. Each glimmer will correspond to a random number between 1 and 6, with an equal chance for each of the six results. The glimmers marked 1-3 represent the elemental power, the ones marked 4-5 represent the breakthrough power, and the one marked 6 is represents the nova power. Yuli will choose the highest-level skill to launch based on the results of the su",
    "ja": "Terraを見つけると、浄化を開始できます。Terraの浄化のプロセスでは、Yukiは 5 つの浄化グリマーを召喚します。各グリマーは 1 から 6 までの数字をランダムに示し、各数が出る確率は同じです。\n1～3は元素の力、4～5は突破力、6はノバパワーを表しており、召喚の結果に応じてYuliが最も高いレベルのスキルを選択して起動します。 良い結果が得られやすくなります。\n1～3は元素の力、4～5は突破力、6はノバパワーを表しており、召喚の結果に応じてユリが最も高いレベルのスキルを選択して起動します。 、良い結果が得られやすくなります。",
    "zh": "找到了Terra，就可以開始凈化了，在凈化Terra的過程中，Yuli會召喚出五個凈化微光，每個微光對應一個1到6之間的隨機數，六種結果的概率均等。\n1-3代表元素之力，4-5代表超越之力，6代表新星之力。Yuli會根據召喚結果選擇最高等級的技能發動。技能等級高，越容易獲得好的結果。",
    "zh_cn": "找到了Terra，就可以开始净化了，在净化Terra的过程中，Yuli会召唤出五个净化微光，每个微光对应一个1到6之间的随机数，六种结果的概率均等。\n1-3代表元素之力，4-5代表超越之力，6代表新星之力。Yuli会根据召唤结果选择最高等级的技能发动。技能等级高，越容易获得好的结果。"
  },
  "purify_label": {
    "en": "“How to play” at Terra Purifying",
    "ja": "Terra 浄化の「遊び方」",
    "zh": "凈化Terra的玩法",
    "zh_cn": "净化Terra的玩法"
  },
  "purify_title": {
    "en": "How to play",
    "ja": "遊び方",
    "zh": "教程",
    "zh_cn": "教程"
  },
  "version": 20,
  "yuli_attr_content": {
    "en": "Each Yuli has both Light and Darkness properties, and each property has two attributes. In total, there are 4 attributes in a Yuli itself. The factors will affect the result of purifying Terra, further affect players’ return.",
    "ja": "各Yuliには光と闇の両方の特性と、それぞれの特性に 2 つの属性があります。合計4属性です。これらの要因はレイラインの浄化の効果に影響を与え、さらにプレイヤーの収入に影響を与えます。",
    "zh": "每個Yuli都同時擁有光明特性和黑暗特性，每種特性又擁有兩種屬性。玉裏本身總共有4個屬性。這些因素都會影響凈化地脈的效果，進一步影響玩家的收益。",
    "zh_cn": "每个Yuli都同时拥有光明特性和黑暗特性，每种特性又拥有两种属性。玉里本身总共有4个属性。这些因素都会影响净化地脉的效果，进一步影响玩家的收益。"
  },
  "yuli_attr_content2": {
    "en": "Each level up, the explorer Yuli can get 2 points in light power and 1 point in darkness power to allocate. The basic stats range of different rarity Yuli are shown as follow:",
    "ja": "レベルアップごとに、Yuliは光の力を 2 ポイント、闇の力を 1 ポイント獲得して分配できます。レアリティ別のYuliの基本属性範囲は以下の通りです。",
    "zh": "每升一級，Yuli可以獲得2點光明之力和1點黑暗之力進行分配。不同稀有度Yuli的基本屬性範圍如下：",
    "zh_cn": "每升一级，Yuli可以获得2点光明之力和1点黑暗之力进行分配。不同稀有度Yuli的基本属性范围如下："
  },
  "yuli_attr_content3": {
    "en": "Beside attributes of the Yuli itself, enhancement chips can also provide attributes that affects the outcome during Terra purifying (See as the Enhancement Chips System part) in other aspects.",
    "ja": "Yuliそのものの属性に加えて、テラの浄化効果に影響を与える属性を別の方法で付与することもできます（チップシステムの強化の項を参照）。",
    "zh": "除了yuli本身的屬性外，還可以在其他方面提供影響Terra凈化效果的屬性（參見強化芯片系統部分）。",
    "zh_cn": "除了yuli本身的属性外，还可以在其他方面提供影响泰拉净化效果的属性（参见强化芯片系统部分）。"
  },
  "yuli_attr_content4": {
    "en": "Increase the consumption reduction rate by 1% at each point, while each Yuli has a basic reduction rate of 20%.",
    "ja": "1ポイントごとに消費軽減率が1%増加しますが、基本軽減率は各Yuliごとに20%です。",
    "zh": "每個點增加1%的消耗減少率，而每個yuli的基礎減少率為20%。",
    "zh_cn": "每个点增加1%的消耗减少率，而每个yuli的基础减少率为20%。"
  },
  "yuli_attr_title": {
    "en": "More about Attributes",
    "ja": "属性の詳細",
    "zh": "關於屬性數值",
    "zh_cn": "关于属性数值"
  },
  "yuli_back": {
    "en": "BACK",
    "ja": "バック",
    "zh": "返回",
    "zh_cn": "返回"
  },
  "yuli_chip_content": {
    "en": "Players can implement Enhancement Chips to enhances the performance of the Yuli in missions to bring higher revenue for themselves.",
    "ja": "プレイヤーはチップを強化することでYuliのパフォーマンスを向上させ、高い利益を得ることができます。",
    "zh": "玩家可以通過強化芯片來提升yuli在任務中的表現，從而為自己帶來更高的收益。",
    "zh_cn": "玩家可以通过强化芯片来提升yuli在任务中的表现，从而为自己带来更高的收益。"
  },
  "yuli_chip_content2": {
    "en": "There are only 4 slots where chips can be implanted, which locates at head, heart, hands and legs. The slots will be unlocked when Yuli gets level 20/30/40/50. When the chips are implanted into Yuli, it will be bound with this Yuli, and other Yulis cannot implant later. The fixed enhancement attributes of the four slots are different. With the increase of the chip level, the fixed enhancement attributes will increase synchronously. The initial level of the chip is level 1, and the level cap is l",
    "ja": "装備チップのスロットは頭、心臓、手、足の4つがあります。Yuliがレベル 20/30/40/50 に達すると、スロットのロックが解除されます。チップがYuliに装備されると、そのチップはそのYuliにバインドされ、将来的に他のYuliに移すことはできなくなります。 4 つのスロットは異なる固定強化属性を持ち、チップレベルの向上に伴い、固定強化属性も同期して増加します。チップの初期レベルはレベル1、上限はレベル10です。",
    "zh": "植入芯片的槽位只有4個，分別位於頭部、心臟、手部和腿部。當 Yuli 達到 20/30/40/50 級時，插槽將被解鎖。芯片植入yuli後，會與這只yuli綁定，以後無法再植入其他yuli。四個插槽的固定增強屬性是不同的。隨著芯片等級的提升，固定強化屬性也會同步提升。芯片初始等級為1級，等級上限為10級。",
    "zh_cn": "植入芯片的槽位只有4个，分别位于头部、心脏、手部和腿部。当 Yuli 达到 20/30/40/50 级时，插槽将被解锁。芯片植入yuli后，会与这只yuli绑定，以后无法再植入其他yuli。四个插槽的固定增强属性是不同的。随着芯片等级的提升，固定强化属性也会同步提升。芯片初始等级为1级，等级上限为10级。"
  },
  "yuli_chip_content3": {
    "en": "Players can level-up chips through synthesis and breakthrough. After each upgrade of the chip, besides the fixed enhancement attribute, a random enhancement attribute will also be obtained. Each upgrade will randomly select one of the following effects to superimpose on the chip, and the level 1 chip will have 1 random enhancement attribute. In one chip, the random enhancement attributes will differ from fixed enhancement attribute.",
    "ja": "プレイヤーは合成と突破でチップをアップグレードできます。チップがアップグレードされるたびに、固定強化属性に加えて、ランダムで強化属性が付与されます。アップグレードごとに、以下の効果のいずれかがランダムに選択されてチップに付与され、レベル 1 のチップにはランダムで 1 つの強化属性が付与されます。同一チップにおいては、ランダム強化属性とは固定強化属性は別のものになります。",
    "zh": "玩家可以通過合成和突破來升級籌碼。芯片每次升級後，除了固定的增強屬性外，還會隨機獲得一個增強屬性。每次升級會隨機選擇以下效果中的一種疊加到芯片上，1級芯片會隨機擁有1個增強屬性。在一個芯片中，隨機增強屬性將不同於固定增強屬性。",
    "zh_cn": "玩家可以通过合成和突破来升级筹码。芯片每次升级后，除了固定的增强属性外，还会随机获得一个增强属性。每次升级会随机选择以下效果中的一种叠加到芯片上，1级芯片会随机拥有1个增强属性。在一个芯片中，随机增强属性将不同于固定增强属性。"
  },
  "yuli_chip_content4": {
    "en": "Chip upgrading will consume chips of the same level, $ARG and $ART. Not all upgrading will be successful. If the upgrading fails, the chip will be damaged and become a defective product with only level 1 fixed enhancement attribute and no random enhancement attribute.",
    "ja": "チップのアップグレードには、同じレベルのチップ、$ARG および $ART が消費されます。すべてのアップグレードが成功するわけではありません。アップグレードに失敗するとチップが破損し、レベル1の固定強化属性のみでランダム強化属性が存在しない不良品となります。",
    "zh": "芯片升級會消耗同級芯片、$ARG和$ART。並非所有升級都會成功。如果升級失敗，芯片就會損壞，成為只有1級固定增強屬性、沒有隨機增強屬性的殘次品。",
    "zh_cn": "芯片升级会消耗同级芯片、$ARG和$ART。并非所有升级都会成功。如果升级失败，芯片就会损坏，成为只有1级固定增强属性、没有随机增强属性的残次品。"
  },
  "yuli_chip_title": {
    "en": "How to play",
    "ja": "遊び方",
    "zh": "怎麽玩",
    "zh_cn": "怎么玩"
  },
  "yuli_chip_upgrade": {
    "en": "Level-up of the Enhancement Chips",
    "ja": "強化されたチップのアップグレード",
    "zh": "強化芯片的升級",
    "zh_cn": "强化芯片的升级"
  }
}