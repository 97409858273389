import axios from "axios";
import _ from "lodash";
import { getToken } from "@/utils/auth";
import appBridging from "./appBridging";
import router from "@/router";

const service = axios.create({
  //axios 默认请求地址  拦截器
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 7000,
});

const pageBack = () => {
  // uni.navigateBack();
};

service.interceptors.request.use(
  (request) => {
    const url = _.get(request, "url", "");
    // 如果是外部链接，不用做任何处理
    if (url.indexOf("/api") === -1) {
      return request;
    }
    const filterList = ["/api/activity/v1/activities"];


    // 如果是APP，那么 header 已经配置好了
    if (appBridging.isApp) {
      request.headers = Object.assign({}, request.headers, appBridging.headers);
    } else {
      request.headers["x-ua-device"] = "web";
      // request.headers["x-c-version"] = "2.0.6"; //请求头统一带上版本号
      // request.headers["x-u-pid"] = localStorage.getItem("x-u-pid");
      // request.headers["x-city-id"] = "8";
      const hasToken = getToken();
      if (hasToken !== undefined) {
        let hasTokenObj = JSON.parse(hasToken);
        request.headers["x-cookie-id"] = hasTokenObj.sessionid;
      }
    }
    // request.headers["x-cookie-id"] ='ly'
    // if (request.method.toUpperCase() === "GET") {
    //   const city_id = _.get(request, "params.city_id", appBridging.headers["x-city-id"] || 8);
    //   if (request.params && !filterList.includes(url)) {
    //     request.params["city_id"] = city_id;
    //   }
    // }

    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);
service.interceptors.response.use(
  (config) => {
    if (_.has(config, "data.data")) {
      return config.data;
    }
    return config;
  },
  (error) => {
    const baseErrorMsg = _.get(error, "message", 200);
    const errorStatus = _.get(error, "response.status", 200);
    const errorMsg = _.get(error, "response.data.msg", "");
    const errorCode = _.get(error, "response.data.code", 200);
    const errorToast = (errorMsg, complete = () => {}) => {
      if (!errorMsg) {
        complete();
        return;
      }
    };

    if (errorStatus >= 500) {
      errorToast("Connection dropped");
    }

    if (baseErrorMsg.includes("timeout")) {
      // this.$toast.show({
      //   msg: "SpinWin not available now, please try again later.",
      //   type: TOAST_TYPE.ERROR,
      // });
    }

    switch (errorStatus) {
      case 400:
        errorToast(errorMsg);
        break;
      case 401:
        // appBridging.toLogin();
        if (!appBridging.checkIsApp()) {
          router.push(`/m/login?redirect=${router.currentRoute.fullPath}`);
        }
        break;
      case 403:
        if (errorCode == 403013) {
          break;
        }

        errorToast(errorMsg);
        if (errorCode == 403002) {
          errorToast("Connection dropped");
        }
        break;
      case 404:
        errorToast(errorMsg, pageBack);
        break;
      default:
        if (errorCode == 401404) {
          if (!appBridging.checkIsApp()) {
            router.push(`/m/login?redirect=${router.currentRoute.fullPath}`);
          }
        }
        errorToast(errorMsg);
    }

    return Promise.reject(error);
  }
);
export default service;
