import request from "@/utils/request.js";
import { DownloadFile } from "@/utils/utils.js";
import { LANGUAGE_PACK } from "./config.js";
import _ from "lodash";
import {
  version,
  ChipHowToPlayI18n,
  GainEntryExplainI18n,
  GuideRoleI18n,
  YuliAttributesI18n,
  YuliHowToPlayI18n,
} from "@/locales";

/**
 * 获取所有所有语言包的资源路径
 * - 用于判断版本号
 * - 用于获取子模块的资源和版本号
 */
export const getAllLanguage = async (module) => {
  let res;
  try {
    res = await DownloadFile(LANGUAGE_PACK.get_all_language);
  } catch (error) {
    res = {};
  }
  const { h5_active_mission, h5_rule_center } = res;
  // 版本判断
  if (version == res?.version) {
    return true;
  } else {
    // 不符合返回最新对应模块数据
    if (module == "getChipHowToPlay" && h5_rule_center) {
      // 芯片玩法说明
      return h5_rule_center;
    } else if (module == "getGainEntryExplain" && h5_rule_center) {
      // yuli属性说明
      return h5_rule_center;
    } else if (module == "getGuideRole" && h5_rule_center) {
      // 角色技能说明
      return h5_rule_center;
    } else if (module == "getYuliAttributes" && h5_rule_center) {
      // 芯片词条属性说明
      return h5_rule_center;
    } else if (module == "getYuliHowToPlay" && h5_rule_center) {
      // 净化地脉玩法说明
      return h5_rule_center;
    } else {
      // 都不符合的情况下 - 沿用本地
      return true;
    }
  }
};

/**
 * 芯片玩法说明-语言包
 */
export const getChipHowToPlayLanguage = async () => {
  const versionConfig = await getAllLanguage("getChipHowToPlay");
  console.log("versionConfig: ", versionConfig);
  if (versionConfig == true) {
    return ChipHowToPlayI18n;
  } else {
    return DownloadFile(versionConfig?.resource_path);
  }
};

/**
 * yuli属性说明-语言包
 */
export const getGainEntryExplainLanguage = async () => {
  const versionConfig = await getAllLanguage("getGainEntryExplain");
  console.log("versionConfig: ", versionConfig);
  if (versionConfig == true) {
    return GainEntryExplainI18n;
  } else {
    return DownloadFile(versionConfig?.resource_path);
  }
};

/**
 * 角色技能说明-语言包
 */
export const getGuideRoleLanguage = async () => {
  const versionConfig = await getAllLanguage("getGuideRole");
  console.log("versionConfig: ", versionConfig);
  if (versionConfig == true) {
    return GuideRoleI18n;
  } else {
    return DownloadFile(versionConfig?.resource_path);
  }
};

/**
 * 芯片词条属性说明-语言包
 */
export const getYuliAttributesLanguage = async () => {
  const versionConfig = await getAllLanguage("getYuliAttributes");
  console.log("versionConfig: ", versionConfig);
  if (versionConfig == true) {
    return YuliAttributesI18n;
  } else {
    return DownloadFile(versionConfig?.resource_path);
  }
};

/**
 * 净化地脉玩法说明-语言包
 */
export const getYuliHowToPlayLanguage = async () => {
  const versionConfig = await getAllLanguage("getYuliHowToPlay");
  console.log("versionConfig: ", versionConfig);
  if (versionConfig == true) {
    return YuliHowToPlayI18n;
  } else {
    return DownloadFile(versionConfig?.resource_path);
  }
};
